export interface LoginPayload {
  grant_type: string;
  login: string;
  password: string;
}

export interface LoginResponse {
  access_token: string;
  token_type: string;
  expires_in: number;
  mfa_type: MFA_TYPE;
  code_token:string;
}

export interface TokenMetadata {
  exp: number;
  iat: number;
  jti: string;
  organization_id: string;
  roles: USER_ROLE[]
  user_full_name: string;
  user_id: string;
  username: string;
}

enum MFA_TYPE {
  EMAIL = 'emai',
  NONE = 'none'
}

enum USER_ROLE {
  ROLE_OWNER = 'ROLE_OWNER',
  ROLE_ADMIN = 'ROLE_ADMIN',
  ROLE_USER = 'ROLE_USER'
}

export const TOKEN_KEY: string = 'romulus-token';
