import { TranslateService } from '@ngx-translate/core';
import { authGuard } from './domains/auth/auth.guard';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';

export const appRoutes: VexRoutes = [
  {
    path: 'login',
    loadComponent: () =>
      import('./pages/auth/login/login.component').then(
        (m) => m.LoginComponent
      )
  },
  {
    path: 'register',
    loadComponent: () =>
      import('./pages/auth/register/register.component').then(
        (m) => m.RegisterComponent
      )
  },
  {
    path: 'forgot-password',
    loadComponent: () =>
      import(
        './pages/auth/forgot-password/forgot-password.component'
      ).then((m) => m.ForgotPasswordComponent)
  },
  {
    path: 'coming-soon',
    loadComponent: () =>
      import('./pages/coming-soon/coming-soon.component').then(
        (m) => m.ComingSoonComponent
      )
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      {
        path: 'inbox',
        loadComponent: () =>
          import(
            './pages/inbox/inbox.component'
          ).then((m) => m.InboxComponent)
      },
      {
        path: 'subscription',
        loadComponent: () =>
          import(
            './pages/subscription/subscription.component'
          ).then((m) => m.SubscriptionComponent)
      },
      {
        path: 'preferences',
        loadComponent: () =>
          import(
            './pages/preferences/preferences.component'
          ).then((m) => m.PreferencesComponent)
      },
      {
        path: 'profile',
        loadChildren: () => import('./pages/profile/profile.routes')
      },
    ]
  }
];
