import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar'
import { TranslateService } from '@ngx-translate/core';

import { DEFAULT_SNACKBAR_CONFIG } from './notification.models';

/**
 * Wrapper around MatSnackBar
 */

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private snackBar: MatSnackBar,
    private translateService: TranslateService
  ) { }

  public notify(message: string, action: string, config?: MatSnackBarConfig) {
    this.snackBar.open(
      this.translateService.instant(message),
      this.translateService.instant(action),
      config ? config : DEFAULT_SNACKBAR_CONFIG
    )
  }

  public error(message: string, action: string, config?: MatSnackBarConfig) {
    this.snackBar.open(
      this.translateService.instant(message),
      this.translateService.instant(action),
      config ? config : DEFAULT_SNACKBAR_CONFIG
    )
  }

}
