import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Observable } from 'rxjs';

import { EXCLUDED_URL_PATTERNS } from './excluded-domains.const';
import { TOKEN_KEY } from '../../domains/auth/auth.models';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {
  private jwt: string = localStorage.getItem(TOKEN_KEY) || '';

  private shouldSkip(url: string): boolean {
    return EXCLUDED_URL_PATTERNS.some((pattern: RegExp) => pattern.test(url))
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.shouldSkip(request.url)) {
      return next.handle(request);
    } else {
      const authReq = request.clone({
        setHeaders: {
          authorization: `Bearer ${this.jwt}`
        }
      });
      return next.handle(authReq);
    }
  }
}
