import { Environment } from "./environment.model";

export const environment: Environment = {
  production: false,
  apiEndpoint: 'https://dashboard.api.test.romulus.live',
  defaultLanguage: 'en',
  supportedLanguages: [
    {
      locale: "it",
      name: "Italiano"
    },
    {
      locale: "en",
      name: "English"
    }],
};
