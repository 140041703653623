import { of, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { ChargebeeInstance, Portal } from 'chargebee-js-types'
import { PortalSections, PortalSession } from './subscription.model';

declare const Chargebee: any;

@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {
  private instance: ChargebeeInstance;
  private session: PortalSession =
    {"id": "portal_BTTwSYUB41Gy3N81", "token": "1yG4BkbnRSDcuF0ZMcWri4lgfgE2lxWEP", "access_url": "https://romulus-test.chargebee.com/portal/v2/authenticate?token=1yG4BkbnRSDcuF0ZMcWri4lgfgE2lxWEP", "status": "created", "created_at": 1714143937, "expires_at": 1714147537, "object": "portal_session", "customer_id": "BTUNi6U9kFiQK6E9", "linked_customers": [{"object": "linked_customer", "customer_id": "BTUNi6U9kFiQK6E9", "email": "me@gabrieleproni.com", "has_billing_address": false, "has_payment_method": false, "has_active_subscription": true}]}


  private sections: {[key:string]: string};
  private portal: Portal | undefined;
  private timeout: number = -1;


  constructor() {
    this.instance = Chargebee.init({
      site: 'romulus-test'
    });
    this.setSession(this.session);
    this.portal = this.instance.createChargebeePortal();
    this.sections = Chargebee.getPortalSections() || PortalSections;
  }

  public openPortal(section: string) {
    this.portal?.openSection(
      {
        sectionType: this.sections?.[section],
        params: {
          subscriptionId: 'BTLzGDU9kG6HE6Nc'
        }
      },
      {
        loaded: console.log,
        close: console.log,
        visit: ()=>{},
        paymentSourceAdd: console.log,
        paymentSourceUpdate: console.log,
        paymentSourceRemove: console.log,
        subscriptionChanged: console.log,
        subscriptionCustomFieldsChanged: console.log,
        subscriptionCancelled: console.log,
        subscriptionResumed: console.log,
        subscriptionPaused: console.log,
        scheduledPauseRemoved: console.log,
        scheduledCancellationRemoved: console.log,
        subscriptionReactivated: console.log,
      }
    );
  }

  public getSession() {
    return of(this.session).pipe(
      tap((session: PortalSession) => {
        const timeDiff = session.expires_at - (+new Date());
        if (this.timeout == -1) {
          this.timeout = setTimeout(
            () => {
              this.refreshSession(session);
              this.timeout = -1;
            },
            timeDiff - 5e3
          ) as unknown as number;
        }
      })
    );
  }
  private refreshSession(s: PortalSession) {
    this.session = s;
    this.setSession(s);
    this.portal = this.instance.createChargebeePortal();
  }

  private setSession(s: PortalSession) {
    this.instance.setPortalSession(() => Promise.resolve(s))
  }

}
