import { Injectable } from '@angular/core';
import { NavigationItem } from './navigation-item.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationLoaderService {
  private readonly _items: BehaviorSubject<NavigationItem[]> =
    new BehaviorSubject<NavigationItem[]>([]);

  get items$(): Observable<NavigationItem[]> {
    return this._items.asObservable();
  }

  constructor() {
    this.loadNavigation();
  }

  loadNavigation(): void {
    this._items.next([
      {
        type: 'link',
        label: 'menu.home',
        route: '/home',
        icon: 'remix:Buildings/home-smile-2-line',
        hoverIcon: 'remix:Buildings/home-smile-2-fill',
        routerLinkActiveOptions: { exact: true }
      }, {
        type: 'link',
        label: 'menu.inbox',
        route: '/inbox',
        icon: 'remix:Business/inbox-line',
        hoverIcon: 'remix:Business/inbox-fill',
        routerLinkActiveOptions: { exact: true }
      }, {
        type: 'link',
        label: 'menu.robocall',
        route: '/robocall',
        icon: 'remix:Device/phone-line',
        hoverIcon: 'remix:Device/phone-fill',
        routerLinkActiveOptions: { exact: true }
      }
    ]);
  }
}
