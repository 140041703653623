import { HttpClient, HttpResponseBase } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, tap, BehaviorSubject } from 'rxjs';

import { LoginResponse, TOKEN_KEY, TokenMetadata } from './auth.models';


const dummyMeta: TokenMetadata = {
  exp: 0,
  iat: 0,
  jti: '',
  organization_id: '',
  roles: [],
  user_full_name: '',
  user_id: '',
  username: ''
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private token: string = '';
  private meta: TokenMetadata = dummyMeta;
  private _meta$: BehaviorSubject<TokenMetadata> = new BehaviorSubject(dummyMeta);

  constructor(private http: HttpClient) {
    const storage = localStorage.getItem(TOKEN_KEY);
    if (storage) {
      this.token = storage;
      const meta = this.jwtDecode(storage);
      if (this.isTokenExpired()) {
        this.token = '';
        this.setMeta(dummyMeta);
      } else {
        this.setMeta(meta);
      }
    }
  }

  get meta$() {
    return this._meta$.asObservable();
  }

  login(username: string, password: string): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${environment.apiEndpoint}/v1/auth/token`,
      {
        grant_type: 'token',
        username,
        password
      }
    ).pipe(
      tap(
        (response: LoginResponse) => {
          this.token = response.access_token;
          if (this.jwtDecode(response.access_token) !== undefined) {
            this.setMeta(
              this.jwtDecode(response.access_token)
            );
          };
          localStorage.setItem(TOKEN_KEY, response.access_token);
        }
      )
    );
  }

  logout() {
    return this.http.post(`${environment.apiEndpoint}/v1/auth/logout`, null, { observe: 'response' })
      .pipe(
        tap(
          (response: HttpResponseBase) => {
            if (response.status === 200) {
              localStorage.removeItem(TOKEN_KEY);
              this.token = '';
              this.setMeta(dummyMeta);
            }
          }
        )
      );
  }

  private setMeta(meta: TokenMetadata) {
    this.meta = meta;
    this._meta$.next(this.meta);
  }

  jwtDecode(jwt: string): TokenMetadata {
    const tokens = jwt.split(".");
    if (tokens.length > 1)
      return JSON.parse(atob(tokens[1]));
    else return dummyMeta;
  }

  isAuthenticated(): boolean {
    const local = this.token;
    const storageToken = localStorage.getItem(TOKEN_KEY);
    if (local !== '' || storageToken !== null) {
      return true;
    } else {
      return false;
    }
  }

  isTokenExpired() {
    const data = this.jwtDecode(this.token);
    const now = +new Date() / 1000;
    return data !== undefined
      ? data.exp <= now
      : true;
  }

}
