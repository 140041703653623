import { MatSnackBarConfig } from '@angular/material/snack-bar'

export const DEFAULT_TIMEOUT = 3000;
export const DEFAULT_SNACKBAR_CONFIG: MatSnackBarConfig = {
  horizontalPosition: 'center',
  verticalPosition: 'top',
  duration: DEFAULT_TIMEOUT
}

export const MAX_NOTIFICATIONS = 5;
