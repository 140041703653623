import { inject } from "@angular/core";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { take } from "rxjs";

import { AuthService } from "./auth.service";
import { DEFAULT_SNACKBAR_CONFIG } from "src/app/core/snackbar.const";

export function authGuard() {
  const router = inject(Router);
  const auth = inject(AuthService);
  const translate = inject(TranslateService);
  const snackbar = inject(MatSnackBar);

  if (auth.isAuthenticated() || !auth.isTokenExpired()) {
    return true;
  } else {
    auth.logout();
    translate.get(['auth.expired', 'common.ok'])
      .pipe(take(1))
      .subscribe(
        (data:{'auth.expired': string, 'common.ok': string}) => {
          snackbar.open(
            data['auth.expired'],
            data['common.ok'],
            DEFAULT_SNACKBAR_CONFIG,
          );
        }
      )
    return router.createUrlTree(["/login"]);
  }
}
