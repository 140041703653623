import {
  EnvironmentProviders,
  inject,
  Provider,
  ENVIRONMENT_INITIALIZER
} from '@angular/core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from './token.interceptor';

export function provideTokenInterceptor(): Array<Provider | EnvironmentProviders> {
  return [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    }
  ];
}
